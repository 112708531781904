import { useIonToast } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { logoutAllStores } from './lib/Helpers/auth';
import { auth } from './lib/Helpers/firebase/firebase';
import { dev } from './lib/Helpers/utils';

interface ProtectedProps {
	children: JSX.Element;
}

export function ProtectedRoute({ children }: ProtectedProps) {
	const { t } = useTranslation();
	const history = useHistory();
	const [presentToast] = useIonToast();
	const isLoggedIn = auth && auth.currentUser;

	if (!isLoggedIn) {
		if (dev)
			presentToast({
				message: t('loginpage.unauthenticated'),
				duration: 3000,
				position: 'bottom',
			});

		setTimeout(
			async () => {
				await logoutAllStores();
				history.replace('/login');
			},
			dev ? 5000 : 500
		);
		return <></>;
	} else {
		return children;
	}
}
