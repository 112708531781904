import { AlertOptions } from '@ionic/core';
import {
	IonButton,
	IonButtons,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonContent,
	IonHeader,
	IonList,
	IonPage,
	IonTitle,
	IonToolbar,
	useIonAlert,
	useIonLoading,
	useIonToast,
} from '@ionic/react';
import { doc, setDoc } from 'firebase/firestore';
import { useFormik } from 'formik';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { CustomIonInputItem } from '../Helpers/CustomIonInputItem';
import { auth, firestore } from '../Helpers/firebase/firebase';
import { checkInviteCode } from '../Invitecodes/check-invite-code';
import { WaitingListEntry } from '../Invitecodes/invite-code.schema';
import { loadUserDataFromFirestore } from '../User/user.store';

import { LogAnalytics } from '../Stats/ga';
import './NewUser.css';

export const newUserInputSchema = z.object({
	inviteCode: z.string().min(6).default(''),
	email: z.string().default(''),
});

type NewUserInput = z.infer<typeof newUserInputSchema>;

export interface NewUserState {
	isChecking: boolean;
	isValid: boolean;
	emailIsvalid: boolean;
	inviteCheckResult:
		| {
				result: boolean;
				error: number;
		  }
		| undefined;
}

export function NewUser() {
	//
	// hooks
	//
	const [presentLoading, dismissLoading] = useIonLoading();
	const [newUserState, setNewUserState] = useState<NewUserState>({
		isChecking: false,
		isValid: false,
		emailIsvalid: false,
		inviteCheckResult: undefined,
	});
	const formik = useFormik<NewUserInput>({
		initialValues: {
			inviteCode: '',
			email: '',
		},
		validationSchema: toFormikValidationSchema(newUserInputSchema),
		onSubmit: async values => {
			if (values.inviteCode.length === 6) {
				submitInviteCode();
			}
		},
	});
	const history = useHistory();
	const [presentAlert] = useIonAlert();
	const [presentToastOrg] = useIonToast();

	// get any query parameter to fill the invitecode
	const queryParams = new URLSearchParams(window.location.search);
	const receivedCode = queryParams.get('invite');
	useEffect(() => {
		LogAnalytics('NewUser', 'start');

		if (receivedCode && receivedCode.length == 6) {
			LogAnalytics('NewUser', 'received_link');
			formik.setFieldValue('inviteCode', receivedCode);
		}
	}, []);

	useEffect(() => {
		if (formik.values.inviteCode.length === 6 && receivedCode && receivedCode.length === 6) submitInviteCode();
	}, [formik.values.inviteCode]);

	//
	// variables and setters
	//

	//
	// methods
	//
	function presentToast(position: 'top' | 'middle' | 'bottom', message: string) {
		presentToastOrg({
			message,
			duration: 3500,
			position: position,
		});
	}

	async function submitInviteCode() {
		LogAnalytics('NewUser', 'submitInviteCode');

		if (formik.values.inviteCode.length != 6) return;

		setNewUserState({
			...newUserState,
			isChecking: true,
		});

		const uid = auth.currentUser?.uid;
		if (uid === undefined) {
			console.error('Error uid is undefined', auth.currentUser);
			history.replace('/');
			window.location.reload();
			return;
		}

		presentLoading({
			message: t('newuser.checkingcode'),
			duration: 53000,
		});

		try {
			const inviteCheckResult = await checkInviteCode(formik.values.inviteCode);

			if (inviteCheckResult) {
				LogAnalytics('NewUser', 'submitInviteCode_ok');

				if (auth.currentUser) await loadUserDataFromFirestore(auth.currentUser.uid);

				setTimeout(() => {
					history.replace('/tabs');
					dismissLoading();
				}, 1000);
			}

			if (!inviteCheckResult) {
				LogAnalytics('NewUser', 'submitInviteCode_error');

				presentToast('top', t('newuser.invalidinvitecode'));

				dismissLoading();
			}
		} catch (e) {
			dismissLoading();
			console.log('error fetch', e);
		} finally {
			setNewUserState({
				...newUserState,
				isChecking: false,
			});
		}
	}

	function logOut() {
		LogAnalytics('NewUser', 'logOut');

		auth.signOut().finally(() => {
			history.replace('/login');
			window.location.reload();
		});
	}

	function signupWaitingList() {
		LogAnalytics('NewUser', 'signupWaitingList');

		const options = {
			header: t('newuser.enteremail'),
			inputs: [
				{
					name: 'email',
					type: 'text',
					placeholder: t('newuser.enteremail'),
				},
			],
			buttons: [
				{
					text: t('general.cancel'),
					role: 'cancel',
					cssClass: 'secondary',
					handler: () => {},
				},
				{
					text: t('general.ok'),
					handler: async (data: { email: string }) => {
						LogAnalytics('NewUser', 'signupWaitingList_ok');
						if (data && data.email && data.email.length > 5 && auth.currentUser) {
							try {
								// firestore rules - allow write waitinglist for own uid
								const docRef = doc(firestore, `waitinglist`, auth.currentUser.uid);
								const now = Date.now();
								const waitingListEntry: WaitingListEntry = {
									created: now,
									email: data.email,
									uid: auth.currentUser.uid,
									phoneNumber: auth.currentUser.phoneNumber as string,
								};
								await setDoc(docRef, waitingListEntry);

								presentToast('middle', t('newuser.validemail'));
								setTimeout(() => {
									auth.signOut().finally(() => {
										history.replace('/login');
										window.location.reload();
									});
								}, 1500);
							} catch (e) {
								console.log('Error setting waitinglist', e);
								presentToast('middle', 'Unknown error');
							}
						} else {
							presentToast('middle', t('newuser.invalidemail'));
						}
					},
				},
			],
		};

		presentAlert(options as AlertOptions);
	}

	return (
		<IonPage>
			<IonHeader translucent={true}>
				<IonToolbar>
					<IonTitle>{t('newuser.welcome')}</IonTitle>
					<IonButtons slot="end" onClick={logOut}>
						{t('profile.logout')}
					</IonButtons>
				</IonToolbar>
			</IonHeader>

			<IonContent fullscreen>
				{
					newUserState.inviteCheckResult == undefined && (
						<>
							<br />
							<div onClick={signupWaitingList} className="nocode">
								{t('newuser.nocode')}
							</div>
							<br />
							<IonCard>
								<IonCardHeader> {t('newuser.welcome')}</IonCardHeader>
								<IonCardContent>
									<br />
									<p>{t('newuser.explain')}</p>
									<br />
									<form onSubmit={formik.handleSubmit}>
										<IonList lines="full" class="ion-no-margin ion-no-padding">
											<CustomIonInputItem
												name="inviteCode"
												required={true}
												placeholder=""
												i18entry="newuser"
												formik={formik}
												inputType="text"
												maxlength={6}
											/>
										</IonList>

										<br />
										<br />
										<IonButton
											disabled={newUserState.isChecking || formik.values.inviteCode.length < 6}
											type="submit"
											class="ion-no-margin"
											expand="block"
										>
											{t('newuser.submit')}
										</IonButton>
									</form>
								</IonCardContent>
							</IonCard>
						</>
					)
					//	disabled={!newUserState.isValid || newUserState.isChecking}
				}

				{newUserState.inviteCheckResult != undefined && newUserState.inviteCheckResult.error == 1 && (
					<IonCard>
						<IonCardHeader> {t('newuser.codealreadyused')}</IonCardHeader>
						<IonCardContent>Code already used dude!</IonCardContent>
					</IonCard>
				)}

				{newUserState.inviteCheckResult != undefined && newUserState.inviteCheckResult.error == 0 && (
					<IonCard>
						<IonCardHeader> {t('newuser.success')}</IonCardHeader>
						<IonCardContent>Code already used dude!</IonCardContent>
					</IonCard>
				)}

				{newUserState.inviteCheckResult != undefined && newUserState.inviteCheckResult.error > 1 && (
					<IonCard>
						<IonCardHeader>Unknown error</IonCardHeader>
						<IonCardContent>
							<pre>{JSON.stringify(newUserState.inviteCheckResult, null, 2)}</pre>
						</IonCardContent>
					</IonCard>
				)}
			</IonContent>
		</IonPage>
	);
}
