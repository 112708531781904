import type { AppConfig } from './config-client.schema';

export const config: AppConfig = {
	appDomain: 'https://ekoopkonek.me',
	countryCode: '+63',
	localeGroup: 'natcco',
	availableLocales: ['en'],
	defaultLocale: 'en',

	pwaItems: {
		name: 'eKoop Konek',
		short_name: 'eKoop Konek',
		description: '',
		theme_color: '#004aa4',
	},

	allowedNumbers: [],

	maxSMSLength: 160,

	// firebaseConfig: {
	// 	apiKey: 'AIzaSyCsjA5JVyBkSLqadFvQdeVez8iC7WsN1-A',
	// 	authDomain: 'natcco-db.firebaseapp.com',
	// 	projectId: 'natcco-db',
	// 	storageBucket: 'natcco-db.appspot.com',
	// 	messagingSenderId: '455722057052',
	// 	appId: '1:455722057052:web:31b2237a9d73adff505599',
	// 	measurementId: 'G-7R0XP08340',
	// 	vapidKey: 'BF0jwIGKRHK_B2bJxjNs-QO7Y-NU12shKacutKs44NnVndhY4MqF7eaQopBmmX0IMdeYAAW95UPZKHvpvzCHIcE',
	// 	recaptchaV3publicKey: '6LdPB1YoAAAAAJibOyadgbp2DBmuHaIOqI3uDN-_',
	// },

	firebaseConfig: {
		apiKey: 'AIzaSyDngXlcx59xiF4tuPJslg2KyZedrBHz_sw',
		authDomain: 'natcco-db-e197e.firebaseapp.com',
		projectId: 'natcco-db-e197e',
		storageBucket: 'natcco-db-e197e.appspot.com',
		messagingSenderId: '669323507992',
		appId: '1:669323507992:web:09386fa2c297b8ab56066d',
		measurementId: 'G-B1R3PND69G',
		vapidKey: '',
		recaptchaV3publicKey: '',
	},

	apiDev: 'http://127.0.0.1:5001/natcco-db-e197e/us-central1/',
	apiProd: 'https://us-central1-natcco-db-e197e.cloudfunctions.net/',
	// apiProd: 'https://us-central1-natcco-db.cloudfunctions.net/',

	appConfig: {
		hasPrivacyNotice: false,
		hasTandC: false,
		experimental: false,
		isNATCCO: true,
		shopActionsConfig: {
			userTooLongInactive: 7 * 24 * 60 * 60 * 1000, // one week
		},
		wantsMiddleName: true,
		systemDown: false,
	},

	tenants: [
		{
			id: '1',
			short_title: 'CamSur',
		},
		{
			id: '2',
			short_title: 'Mambajao',
		},
		{
			id: '3',
			short_title: 'Barbaza',
		},
		{
			id: '4',
			short_title: 'NATCCO',
		},
		{
			id: '5',
			short_title: 'MHRMC',
		},
	],
};

export enum ShopType {
	AGRIBUSINESS = 'AGRIBUSINESS',
	RETAIL = 'RETAIL',
	// WHOLESALE = 'WHOLESALE',
	TENANT = 'TENANT',
	MPCSHOP = 'MPCSHOP',
	// OUTLET = 'OUTLET',
}
