/* eslint-disable no-inner-declarations */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { config } from './assets/config';

// Default translations
import defaultEN from './locales/default/en.json';

// Oima translations
import oimaEN from './locales/oima/en.json';
import oimaFR from './locales/oima/fr.json';
import oimaNL from './locales/oima/nl.json';

// Retailink translations
import retailinkEN from './locales/retailink/en.json';
import retailinkES from './locales/retailink/es.json';
import retailinkFR from './locales/retailink/fr.json';

// FED translations
import fedAL from './locales/fed/al.json';

// SIDC translations
import sidcTG from './locales/sidc/tg.json';

// NATCCO translations
import natccoEN from './locales/natcco/en.json';

import { dev } from './lib/Helpers/utils';

export const resources = {
	default_en: {
		translation: defaultEN,
	},
	oima_en: {
		translation: oimaEN,
	},
	oima_fr: {
		translation: oimaFR,
	},
	oima_nl: {
		translation: oimaNL,
	},
	sidc_tg: {
		translation: sidcTG,
	},
	natcco_en: {
		translation: natccoEN,
	},
	fed_al: {
		translation: fedAL,
	},
	retailink_en: {
		translation: retailinkEN,
	},
	retailink_fr: {
		translation: retailinkFR,
	},
	retailink_es: {
		translation: retailinkES,
	},
};

const defaultLocale = 'default_en';

i18n.use(initReactI18next).init({
	resources,
	lng: `${config.localeGroup}_${config.defaultLocale}`,
	fallbackLng: defaultLocale,
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;

/*

This part does the check on missing translations

How to create translations:
- see the differential in the console log in dev mode, enabling the right language - see init of i18n below
- put it in ChatGPT with the prompt :"Convert this JSON object from English to Albanian. Only translate the value, not the keys."
- place the translation in the place in the translation file

all this code may need to go to the admin panel as this is not really the best place to put this

*/
if (dev) {
	const missingTranslations = {};

	function addMissing(page, label, translation) {
		if (translation.length == 0) return;
		if (!missingTranslations[page]) missingTranslations[page] = {};
		if (!missingTranslations[page][label]) missingTranslations[page][label] = {};
		missingTranslations[page][label] = translation;
	}

	const transToCheck = resources[`${config.localeGroup}_${config.defaultLocale}`]['translation'];
	Object.keys(resources[defaultLocale]['translation']).forEach(page => {
		const labels = Object.keys(resources[defaultLocale]['translation'][page]);
		labels.forEach(label => {
			if (!transToCheck[page]) {
				addMissing(page, label, resources[defaultLocale]['translation'][page][label]);
			} else if (!transToCheck[page][label]) addMissing(page, label, resources[defaultLocale]['translation'][page][label]);
		});
	});

	if (Object.keys(missingTranslations).length > 0) console.warn('Missing translations', JSON.stringify(missingTranslations, null, 2));
}
