import { collection, collectionGroup, doc, getDocs, writeBatch } from 'firebase/firestore';
import { BehaviorSubject } from 'rxjs';
import { config } from '../../assets/config';
import { firestore } from '../Helpers/firebase/firebase';
import { capitalizeEachWord, formatMobileNumber, increaseObjectCountMapKey } from '../Helpers/utils';
import { Shop } from '../Shop/shop.schema';
import { shopStore$, updateFarmersForShop } from '../Shop/shop.store';
import { StatsKeys } from '../Stats/stats-keys';
import { saveStatsToRemote, setStatsKeyCount, setStatsKeyObject } from '../Stats/stats.store';
import { User } from '../User/user.schema';
import { Farmer, FarmerStatus, farmerSchema } from './farmer.schema';

export const farmersStore$ = new BehaviorSubject<Farmer[]>([]);

// if we are in multi-tenant and there is a multi-tenant defined, we give that path
function getDocPath(shopID: string, user: User) {
	if (user == undefined) {
		console.error('GetDoc with undefined user received for farmers');
		return 'error';
	}

	if (user.active_tenant_id && user.active_tenant_id.length > 0) return `tenants/${user.active_tenant_id}/shops/${shopID}/farmers`;
	if (config.tenants.length == 1) return `tenants/${config.tenants[0].id}/shops/${shopID}/farmers`;
	return `stores/${shopID}/farmers`;
}

// export function setupFarmerStore() {}

export function logOutFarmerStore() {
	farmersStore$.next([]);
}

export async function updateAddFarmer(farmerRawData: Farmer, user: User | undefined) {
	return updateAddFarmers([farmerRawData], user);
}

export async function updateAddFarmers(farmersRawData: Farmer[], user: User | undefined) {
	if (user == undefined) return;

	// let's chunk the data into 400 items
	const chunkedData = [];
	let i, j, temparray;
	const chunk = 400;
	for (i = 0, j = farmersRawData.length; i < j; i += chunk) {
		temparray = farmersRawData.slice(i, i + chunk);
		chunkedData.push(temparray);
	}

	if (config.appConfig.experimental) console.log('Chbnks', chunkedData.length, chunkedData);

	// let's process the chunks with a for loop
	const allBatchesPromise = [];
	const farmerAddList: Farmer[] = [];
	for (let i = 0; i < chunkedData.length; i++) {
		const batch = writeBatch(firestore);

		const farmersInBatch = chunkedData[i];
		farmersInBatch.forEach(farmerRawData => {
			const farmer = { ...farmerRawData, updatedAt: Date.now() };

			farmerAddList.push(farmer);
			const docRef2 = doc(firestore, getDocPath(farmer.shop_id, user), farmer._id);
			batch.set(docRef2, farmer);
		});

		allBatchesPromise.push(batch.commit());
	}

	const idsOfNewFarmers = farmerAddList.map(farmer => farmer._id);

	return Promise.all(allBatchesPromise)
		.then(() => {
			const newList = farmersStore$
				.getValue()
				.filter(farmerInList => farmerInList.status !== FarmerStatus.Disabled)
				.filter(farmerInList => !idsOfNewFarmers.includes(farmerInList._id))
				.concat(farmerAddList);

			updateFarmersForShop(newList, shopStore$.getValue()[0], user);

			farmersStore$.next([...newList]);
			return true;
		})
		.catch(e => {
			console.error('ERROR updating farmers', e);
			return false;
		});
}

export function farmerMigrationCheck(rawData: any): Farmer {
	if (!rawData.status) rawData['status'] = FarmerStatus.Active;

	if (rawData.first_name && rawData.last_name) {
		rawData.first_name = capitalizeEachWord(rawData.first_name);
		rawData.last_name = capitalizeEachWord(rawData.last_name);
	}

	if (rawData.middle_name) rawData.middle_name = capitalizeEachWord(rawData.middle_name);

	// a fix on mobile number (issue 296) - sometimes a number can start with one 0, and there can be spaces in the number
	if (rawData.mobile_number) rawData.mobile_number = formatMobileNumber(rawData.mobile_number, config.countryCode);

	const empty = farmerSchema.parse({});
	return { ...empty, ...rawData } as Farmer;
}

export async function loadFarmersFromShop(fromShop: Shop, user: User | undefined) {
	if (user == undefined) return;

	const collectionRef = collection(firestore, getDocPath(fromShop._id, user));

	return getDocs(collectionRef)
		.then(async collectionSnap => {
			let farmerlist: Farmer[] = [];
			collectionSnap.forEach(doc => {
				const farmerLoaded = farmerMigrationCheck(doc.data());
				farmerlist = [...farmerlist, farmerLoaded];
			});

			if (farmerlist.length == 0) {
				const noMultiTenantPath = `stores/${fromShop._id}/farmers`;
				const noMultiTenantCollectionRef = collection(firestore, noMultiTenantPath);

				let farmerRawList: Farmer[] = [];
				const collectionSnap = await getDocs(noMultiTenantCollectionRef);
				collectionSnap.forEach(doc => {
					const farmerLoaded = farmerMigrationCheck(doc.data());
					farmerRawList = [...farmerlist, farmerLoaded];
					farmerlist = [...farmerlist, farmerLoaded];
				});

				// let's put the stuff in the new place - we have stuff to migrate, so also delete old stuff
				if (farmerRawList.length > 0 && user.active_tenant_id != '') {
					const batch = writeBatch(firestore);
					const collectionPath = getDocPath(fromShop._id, user);
					console.warn('Found farmers to migrate', farmerRawList.length, collectionPath);
					farmerRawList.forEach(farmer => {
						const docRef1 = doc(firestore, collectionPath, farmer._id);
						batch.set(docRef1, farmer);

						const docRef2 = doc(firestore, noMultiTenantPath, farmer._id);
						batch.delete(docRef2);

						// const docRef3 = doc(firestore, "farmers", farmer._id);
						// batch.delete(docRef3);
					});
					await batch.commit();
				} else console.warn("NOT migrating, because we don't know which tenant or no farmers", farmerRawList.length, user.active_tenant_id);
			}

			runFarmerStats(farmerlist);

			farmersStore$.next(farmerlist.filter(farmer => farmer.status != FarmerStatus.Disabled));

			return true;
		})
		.catch(e => {
			console.log('ERROR loading farmerlist', `stores/${fromShop._id}/farmers`, e);
			farmersStore$.next([]);
			return e;
		});
}

/*
  Farmer stats:

  - count of farmers
  - crops in farmer database
*/
function runFarmerStats(farmerlist: Farmer[]) {
	const result = convertFarmerListIntoStats(farmerlist);

	Object.keys(result).forEach(key => {
		if (typeof result[key] == 'object') {
			setStatsKeyObject(key as StatsKeys, result[key]);
		}

		if (typeof result[key] == 'number') {
			setStatsKeyCount(key as StatsKeys, result[key] as number);
		}
	});

	saveStatsToRemote();
}

export function convertFarmerListIntoStats(farmerlist: Farmer[]) {
	const resultOfCount: { [key: string]: number | object } = {};

	const activeFarmers = farmerlist.filter(farmer => farmer.status == FarmerStatus.Active);
	resultOfCount[StatsKeys.FARMER_count] = activeFarmers.length;

	let cropList: { [key: string]: number } = {};

	activeFarmers.forEach(farmer => {
		// let's count farmers
		farmer.list_of_crops.forEach(crop => {
			cropList = { ...increaseObjectCountMapKey(cropList, crop, 1) };
		});
	});

	resultOfCount[StatsKeys.CROP_count] = cropList;

	return resultOfCount;
}

export async function provideAllFarmerStats() {
	const farmer_list = await provideAllFarmers();
	return convertFarmerListIntoStats(farmer_list);
}

export async function provideAllFarmers(): Promise<Farmer[]> {
	const collectionGroupRef = collectionGroup(firestore, 'farmers');
	return getDocs(collectionGroupRef).then(async collectionSnap => {
		let list: Farmer[] = [];
		collectionSnap.forEach(doc => {
			list = [...list, doc.data() as Farmer];
		});

		return list;
	});
}
