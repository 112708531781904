import { config } from '../../../assets/config';
import { getHttpOptionsForREST } from '../firebase/firebase';
import { dev } from '../utils';
import { NotificationMessageInterface } from './notification-message.schema';

/**
 * Sends a push notification to the server.
 * @param notificationMessage The notification message to be sent.
 * @returns A Promise that resolves with the server response.
 * @throws An error if the server response status is not 200.
 */
export async function sendPushNotificationToServer(notificationMessage: NotificationMessageInterface): Promise<any> {
	return;

	const url = (dev ? config.apiDev : config.apiProd) + 'pushnotifications';
	const httpOptions = await getHttpOptionsForREST();
	try {
		const fullResponse: Response = await fetch(url, {
			method: 'POST',
			headers: httpOptions.headers,
			body: JSON.stringify(notificationMessage),
		});

		const json = await fullResponse.json();
		if (fullResponse.status === 200) {
			return json;
		} else {
			console.log('Error sending push notification to server: ', json);
			throw new Error(json.message);
		}
	} catch (error) {
		console.log('Error sending push notification to server: ', error);
		throw new Error('General error server response status is not 200');
	}
}
