import { collection, getDocs } from 'firebase/firestore';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { config } from '../../assets/config';
import { firestore } from '../Helpers/firebase/firebase';
import { userStore$ } from '../User/user.store';
import { Transaction } from './transaction.schema';

export const transactionsStore$ = new BehaviorSubject<Transaction[]>([]);

export function logOutTransactionStore() {
	transactionsStore$.next([]);
}

export function transactionMigrationCheck(rawData: any): Transaction {
	if (!rawData['tenant_id']) rawData['tenant_id'] = '';
	if (config.tenants.length == 1) rawData['tenant_id'] = config.tenants[0].id;
	const currentUser = userStore$.getValue();
	if (currentUser && currentUser.active_tenant_id.length > 0) rawData['tenant_id'] = currentUser.active_tenant_id;

	return rawData;
}

export const loadTransactionsForID = async (uid: string) => {
	const collectionRef = collection(firestore, `users/${uid}/transactions`);

	return getDocs(collectionRef)
		.then(collectionSnap => {
			let transactionlist: Transaction[] = [];
			collectionSnap.forEach(doc => {
				// here the place to do migration of data
				const loadedTrans = transactionMigrationCheck(doc.data());
				transactionlist = [...transactionlist, loadedTrans];
			});

			transactionsStore$.next(transactionlist);
			return true;
		})
		.catch(e => {
			console.log('ERROR loading transactionlist', uid);
			transactionsStore$.next([]);
			return e;
		});
};
